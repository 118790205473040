import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const history = useHistory();
	const [alert, setAlert] = useState([]);
	const [user_id, setUserID] = useState('');
	const [email, setEmail] = useState('');

	useEffect(() => {
		
		const getUserID = async () => {

			try{
				const user    = await Auth.currentAuthenticatedUser();
				const user_id = user.username;
				const email   = user.attributes.email;
				setUserID(user_id);
				setEmail(email);
			}
			catch(err){
				setAlert([err.message]);
			}
		};
		
		getUserID();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	return (
		<Container style={{ marginTop: 100, marginBottom: 200 }}>
			<ModalAlert data={alert} />
			<Form>
				<Form.Group as={Row} controlId="formUserName">
					<Col sm={{ span: 2, offset: 2 }}>
						<Form.Label>アカウントID</Form.Label>
					</Col>
					<Col sm={6}>
						<Form.Control plaintext readOnly defaultValue={user_id} />
					</Col>
				</Form.Group>
				<Form.Group as={Row} controlId="formEmail">
					<Col sm={{ span: 2, offset: 2 }}>
						<Form.Label>メールアドレス</Form.Label>
					</Col>
					<Col sm={6}>
						<Form.Control plaintext readOnly defaultValue={email} />
					</Col>
				</Form.Group>
				<Form.Group as={Row} controlId="formPassword">
					<Col sm={{ span: 2, offset: 2 }}>
						<Form.Label>パスワード</Form.Label>
					</Col>
					<Col sm={6}>
						<Form.Control plaintext readOnly defaultValue="*****" />
					</Col>
				</Form.Group>
				<Row style={{marginTop: "40px"}}>
					<Col sm={{ span: 8, offset: 2 }}>
						<Button variant="dark" onClick={() => history.push('/account/email/')}>メールアドレスを変更する</Button>
						　
						<Button variant="dark" onClick={() => history.push('/account/password/')}>パスワードを変更する</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
}

export default withRouter(Default);


