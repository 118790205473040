import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [item, setItem] = useState({});
	
	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.Param.Scan();
			//console.log(ref.items);

			const newData = {};

			newData.shipping = ref?.items?.find((v) => v.name === 'shipping')?.value ?? 0;
			newData.free_shipping_price = ref?.items?.find((v) => v.name === 'free_shipping_price')?.value ?? 0;
			
			//データ取得
			setItem(newData);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleUpdate = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params1 ={
				name : 'shipping',
				value : formObj.shipping.value,
			};
			//console.log(params);
			
			//更新
			const ref1 = await API.Param.Update(params1);
			console.log(ref1);

			//エラー確認
			if(ref1.error){
				throw new Error('入力エラーがありました。(' + ref1.error + ')');
			}
			
			const params2 ={
				name : 'free_shipping_price',
				value : formObj.free_shipping_price.value,
			};
			//console.log(params);
			
			//更新
			const ref2 = await API.Param.Update(params2);
			console.log(ref2);

			//エラー確認
			if(ref2.error){
				throw new Error('入力エラーがありました。(' + ref2.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
		
	const handleClose = () => {
		setAlert([]);
		setReload(reload+1);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/shipping/">送料</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleUpdate}>

					<Row>
						<Col sm={6}>
							<p><big>送料</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'送料'} name={'shipping'} type="number" value={item.shipping} required={true} />
					<FormText label={'送料無料になる金額'} name={'free_shipping_price'} type="number" value={item.free_shipping_price} required={true} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >更新</Button>
						</Col>
					</Row>
				</Form>
				
			</Container>
		);
	}
};

export default withRouter(Default);
