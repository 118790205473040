import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Cart = {};

Cart.Get = async (item_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getCart, { item_id: item_id }));
		const item = ref.data.getCart;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Cart.Query = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryCart));
		let items = ref.data.queryCart.items;
		while (ref.data.queryCart.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryCart, { nextToken: ref.data.queryCart.nextToken }));
			items = items.concat(ref.data.queryCart.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Cart.Add = async (params) =>  {
		
	try{
		const ref = await Cart.Get(params.item_id);
		if(Object.keys(ref.item).length ===0){
			return(await Cart.Put(params));
		}
		else{
			params.piece = Number(ref.item.piece) + Number(params.piece);
			return(await Cart.Update(params));
		}
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Cart.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putCart, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Cart.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateCart, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Cart.Delete = async (item_id) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteCart, { item_id: item_id }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Cart;


