import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Category2 = {};

Category2.Get = async (category1_code, category2_code) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getCategory2, { category1_code: category1_code, category2_code: category2_code }));
		const item = ref.data.getCategory2;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		//console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Category2.Query = async (category1_code) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryCategory2, { category1_code: category1_code }));
		//console.log(ref);
		let items = ref.data.queryCategory2.items;
		while (ref.data.queryCategory2.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryCategory2, { category1_code: category1_code, nextToken: ref.data.queryCategory2.nextToken }));
			items = items.concat(ref.data.queryCategory2.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Category2.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putCategory2, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Category2.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateCategory2, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Category2.Delete = async (category1_code, category2_code) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteCategory2, { category1_code: category1_code, category2_code: category2_code }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Category2;
