import { useState, useEffect } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import CSVDownload from '../../Objects/CSVDownload';


const Default = () => {

	const history = useHistory();
	const { order_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [csv, setCSV] = useState([]);
	const [csvName, setCsvName] = useState(null);
	const [selectIDs, setSelectIDs] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Shipment.Query(order_id);

				//console.log(ref);
				
				//データセット
				setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	
	const handleCreateCSV = () => {
	
		const csvData = [];
		
		//対象データの抽出
		for(const item of data){
			if(selectIDs.includes(item.shipment_id)){
				const params = {
					order_date : item.order_date,
					shipping_date : item.shipping_date,
					item_id : item.item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					order_num : item.order_num,
					tracking_no : item.tracking_no,
				};
				csvData.push(params);
			}
		}
		
		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName('shipment_' + dateText + '.csv');

		//CSVデータのセット
		setCSV(csvData);
	};
	
	const formatNum = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()}
			</div>
		);
	};
		
	const formatDate = (cell) => {
		if (cell) {
			// YYYYMMDD形式の文字列を年、月、日に分割
			const year = cell.substring(0, 4);
			const month = cell.substring(4, 6);
			const day = cell.substring(6, 8);
			
			// フォーマットして表示
			const dateText = `${year}年${month}月${day}日`;
			return (
				<div>
					{dateText}
				</div>
			);
		} else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};	

	const formatNumMobile = (cell, row) => {
		return(Number(cell ?? 0).toLocaleString());
	};
		
	const formatDateMobile = (cell) => {
		if (cell) {
			// YYYYMMDD形式の文字列を年、月、日に分割
			const year = cell.substring(0, 4);
			const month = cell.substring(4, 6);
			const day = cell.substring(6, 8);
			
			// フォーマットして表示
			const dateText = `${year}年${month}月${day}日`;
			return(dateText);
		}
		return(cell);
	};	

	const columns = [
		{
			dataField: 'order_date',
			text: '受注日',
			sort: true,
			formatter: formatDate,
		},
		{
			dataField: 'shipping_date',
			text: '出荷日',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatDate,
		},
		{
			dataField: 'order_no',
			text: '受注No.',
			sort: true,
		},
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
		},
		{
			dataField: 'order_num',
			text: '発注数',
			sort: true,
			formatter: formatNum,
		},
		{
			dataField: 'shipment_num',
			text: '出荷数',
			sort: true,
			formatter: formatNum,
		},
		{
			dataField: 'tracking_no',
			text: '問い合せNo.',
			sort: true,
		},
	];
	
	const mobileColumns = [
		{
			dataField: 'shipment_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>受注日</td>
							<td>{formatDateMobile(row.order_date)}</td>
						</tr>
						<tr>
							<td>出荷日</td>
							<td>{formatDateMobile(row.shipping_date)}</td>
						</tr>
						<tr>
							<td>受注No.</td>
							<td>{row.order_no}</td>
						</tr>
						<tr>
							<td>JANコード</td>
							<td>{row.item_id}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						<tr>
							<td>発注数</td>
							<td>{formatNumMobile(row.order_num)}</td>
						</tr>
						<tr>
							<td>出荷数</td>
							<td>{formatNumMobile(row.shipment_num)}</td>
						</tr>
						<tr>
							<td>問い合せNo.</td>
							<td>{row.tracking_no}</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				
				<Row>
					<Col sm={12}>
						<h4>出荷状況　{order_id ? ('（受注番号：' + order_id + '）') : ''}</h4>
					</Col>
				</Row>
				<Row style={{display : isMobile ? 'none' : 'block'}}>
					<Col sm={12}>
						<Form onSubmit={handleSearch}>
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　<font color="white">{ view.length ? view.length.toLocaleString() : '0' }</font>　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
										<Form.Control name="search_key" type="text" placeholder="Search" />
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={6} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'shipment_id'} data={view} columns={isMobile ? mobileColumns : columns} checkbox={isMobile ? false : true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} sort={ { field: 'order_date', order: 'desc'} } />
					</Col>
				</Row>
				<Row style={{ marginTop: 10 }}>
						<Col>
					        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} >もどる</Button>　
							{!isMobile &&
								(
									<CSVDownload data={csv}	filename={csvName} onClick={handleCreateCSV} >
										CSV出力
									</CSVDownload>
								)
							}
						</Col>
					</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
