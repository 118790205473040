import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Item = {};

Item.Get = async (item_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getItem, { item_id: item_id }));
		const item = ref.data.getItem;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Item.Scan = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanItem));
		let items = ref.data.scanItem.items;
		while (ref.data.scanItem.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanItem, { nextToken: ref.data.scanItem.nextToken }));
			items = items.concat(ref.data.scanItem.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Item.QueryProduct = async (product_no) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryItemProduct, { product_no: product_no } ));
		//console.log(ref);
		let items = ref.data.queryItemProduct.items;
		while (ref.data.queryItemProduct.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryItemProduct, { product_no: product_no, nextToken: ref.data.queryItemProduct.nextToken }));
			items = items.concat(ref.data.queryItemProduct.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Item.QueryGenre = async (genre_code) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryItemGenre, { genre_code: genre_code } ));
		let items = ref.data.queryItemGenre.items;
		while (ref.data.queryItemGenre.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryItemGenre, { genre_code: genre_code, nextToken: ref.data.queryItemGenre.nextToken }));
			items = items.concat(ref.data.queryItemGenre.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Item.QueryCategory = async (category1_code, category2_code) =>  {
	try{
		console.log(category1_code, category2_code);
		let ref = await API.graphql(graphqlOperation(GraphQL.queryItemCategory, { category1_code: category1_code, category2_code: category2_code } ));
		console.log(category1_code, category2_code, ref);
		let items = ref.data.queryItemCategory.items;
		while (ref.data.queryItemCategory.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryItqueryItemCategoryemGenre, { category1_code: category1_code, category2_code: category2_code, nextToken: ref.data.queryItemCategory.nextToken }));
			items = items.concat(ref.data.queryItemCategory.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Item.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putItem, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Item.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateItem, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Item.Delete = async (item_id) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteItem, { item_id: item_id }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Item;


