import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import NotFound from '../../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { category1_code } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [item, setItem] = useState({});
	
	useEffect(() => {
	
		const getData = async (category1_code) => {
			
			const ref = await API.Category1.Get(category1_code);
			//console.log(ref);
			
			setItem(ref.item);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData(category1_code);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleUpdate = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params ={
				category1_code : item.category1_code,
				category1_name : formObj.category1_name.value,
			};
			//console.log(params);
			
			//更新
			const ref = await API.Category1.Update(params);
			console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//削除
			const ref = await API.Category1.Delete(item.category1_code);
			console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('実行エラーがありました。(' + ref.error + ')');
			}
			
			//エラー確認
			if(!ref.result){
				throw new Error('実行エラーがありました。');
			}
			
			throw new Error('削除しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const dateShaping = (datetime) => {
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
		return(year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒');
	
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/category1/';
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(Object.keys(item).length === 0){
		return (
			<NotFound />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/category1/">親カテゴリ情報</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleUpdate}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'登録日時'} name={'date_add'} value={dateShaping(item.date_add)} readOnly={true} />
					<FormText label={'親カテゴリーコード'} name={'category1_code'} value={item.category1_code} readOnly={true} />
					<hr/>
					<FormText label={'親カテゴリー名'} name={'category1_name'} value={item.category1_name} required={true} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >更新</Button>
								　
								<Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
				
				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block="true" disabled={lock} >削除</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
