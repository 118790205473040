import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Upload = {};

Upload.Add = async (file, file_name) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.executeUpload, { file, file_name}));
		const result = ref.data.executeUpload.result;
		const error = ref.data.executeUpload.error;
		return({
			result: result ? true : false,
			error: error ? error : null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Upload;


