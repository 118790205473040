//アカウント登録の確認コードを再送する

import { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
//import { AuditLog } from '../../Objects/AuditLog';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const history = useHistory();
	const { email } = useParams();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);

	const handleSubmit= async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLock(true);
		
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}

			//Formから直接取得する更新項目
			const email = formObj.email.value;

			//サインアップ確認
			const result = await Auth.resendSignUp(email);
			
			//debug
			console.log(result);

			//成功
			setSuccess(true);

		}
		catch(err) {
			setAlert([err.message]);
		}
		
		//ロック解除
		setLock(false);
		
	};

	const handleClick = async (event) => {
		window.location.href='/signup-verify' + (email ? '/' + email : '') + '/';
	};

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item href="/">ログイン</Breadcrumb.Item>
				<Breadcrumb.Item href="/signup/">ユーザ登録</Breadcrumb.Item>
				<Breadcrumb.Item active>検証コードの再送</Breadcrumb.Item>
			</Breadcrumb>

			<Container style={{ marginTop: 200, marginBottom: 200 }}>
				<ModalAlert data={alert} />
				
				<Modal show={success} onHide={handleClick}>
					<Modal.Body>
						確認コードを再送しました。<br/>
						次のページで受け取った確認コードを入力してください。<br/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClick}>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
				
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								<big>検証コードの再送</big><br/>
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" defaultValue={email ? email : ''} required disabled={lock} autoComplete="off" />
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >再送</Button>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="secondary" type="button" block="true" onClick={ ()=>{ history.goBack(); } } disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		</div>
	);
}

export default withRouter(Default);
