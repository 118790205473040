import {
	Alert,
	Container,
	Row,
	Col,
} from 'react-bootstrap';
import React from 'react';

const Default = () => {

	return (
		<Container style={{ marginTop: 100, marginBottom: 200 }}>
			<hr/>
			Not Found Error
			<Row>
			<Col sm={8} smoffset={2}>
				<Alert>
					ページが見つかりません。<br/>
				</Alert>
			</Col>
			</Row>
			<hr/>
		</Container>
	);
};

export default Default;

