import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Order = {};

Order.Get = async (order_id) =>  {

	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getOrder, { order_id: order_id }));
		const item = ref.data.getOrder;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Order.Query = async (order_id, count) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryOrder, { order_id : order_id, count : count ? true : false }));
		let items = ref.data.queryOrder.items;
		while (ref.data.queryOrder.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryOrder, { nextToken: ref.data.queryOrder.nextToken, order_id : order_id, count : count ? true : false }));
			items = items.concat(ref.data.queryOrder.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Order.Scan = async (count) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanOrder, { count : count ? true : false }));
		let items = ref.data.scanOrder.items;
		while (ref.data.scanOrder.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanOrder, { nextToken: ref.data.scanOrder.nextToken, count : count ? true : false }));
			items = items.concat(ref.data.scanOrder.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Order.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putOrder, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Order.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateOrder, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Order;


