//ユーザ登録

import { useState  } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';

const Default = () => {

	const history = useHistory();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);
	const [email, setEmail] = useState(false);
	const [flag, setFlag] = useState(false);

	const handleSubmit = async (event) => {

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);

		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//同意の確認
			if(flag === false){
				throw new Error('ご利用規約およびプライバシーポリシーに同意してください。');
			}
			
			//Formから直接取得する更新項目
			const email         = formObj.email.value;
			const passwd        = formObj.passwd.value;

			//サインアップ
			const result = await Auth.signUp(email, passwd);
			
			//debug
			console.log(result);
			
			//記録
			setEmail(email);

			//成功
			setSuccess(true);
		}
		catch(err) {
			setAlert([err.message]);
		}

		//ロック解除
		setLock(false);
		
	};

	const handleAgreeClick = (event) => {
		if(event.target.checked === true){
			setFlag(true);
		}
		else{
			setFlag(false);
		}
	};

	const handleClick = (event) => {
		window.location.href='/signup-verify' + (email ? '/' + email : '') + '/';
	};

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item href="/">ログイン</Breadcrumb.Item>
				<Breadcrumb.Item active>ユーザ登録</Breadcrumb.Item>
			</Breadcrumb>
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} />
				
				<Modal show={success} onHide={handleClick}>
					<Modal.Body>
						確認のためのメールアドレス宛に確認コードを送信しました<br/>
						次のページで受け取った確認コードを入力してください。<br/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClick}>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
				
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								<big>ユーザ登録</big><br/>
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								ユーザ登録のために登録するメールアドレスとパスワードを入力してください。
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 6, offset: 3 }} style={{ marginBottom: 50 }}>
							パスワードは8〜255文字で、大文字1文字、小文字1文字、数字1文字以上である必要があります。
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" required disabled={lock} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="passwd">
							        <Form.Control name="passwd" type="password" placeholder="新しいパスワード" required minLength="8" maxLength="255" disabled={lock} autoComplete="off" />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="agree">
						        	<Form.Check inline name="agree" type="checkbox" id="agree" label="ご利用規約およびプライバシーポリシーに同意する" onClick={handleAgreeClick} disabled={lock} />
							</Form.Group>
						</Col>
					</Row>
					
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >新規登録</Button>
						        <p style={{ marginTop: 10 }}>確認コードを発行済みの方は<a href="/signup-verify/">こちら</a>から確認コードを入力することができます。</p>
						        <p style={{ marginTop: 10 }}>また確認コードが届かない方は<a href="/signup-resent/">こちら</a>から確認コードを再送依頼することができます。</p>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="secondary" type="button" block="true" onClick={ ()=>{ history.goBack(); } } disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		</div>
	);
}

export default withRouter(Default);
