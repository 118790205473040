import API from '../../../../@onocomm-system';

const converShipmentData = async (data) => {

	//不足するデータを補完する

	//ユーザ情報の取得
	const refUser = await API.User.Scan();
	const itemsUser = refUser.items;

	//console.log(data);

	//新規データ
	const new_data = [];

	for(const item of data){

		const user_no = item.user_no;
		const order_date = item.order_date;
		const delivery_kbn = item.delivery_kbn;
		const item_id = item.item_id;
		const order_num = item.order_num;
		const user_order_no = item.user_order_no;
		const biko = item.biko;
		const order_id = item.order_id;
		const pre_order_flag = item.pre_order_flag;
		const pre_order_request = item.pre_order_request;
		const shipping = item.shipping;
		const order_no = item.order_no;
		const tracking_no = item.tracking_no;
		const shipment_num = item.shipment_num;
		const backorder_num = item.backorder_num;
		const processing_date = item.processing_date;
		const due_date = item.due_date;
		const backorder_days = item.backorder_days;
		const shipping_date = item.shipping_date;

		if(!item_id || !user_no || !order_no || !backorder_num){
			//console.log('Invalid data');
			continue;
		}

		const shipment_id = order_no + '-' + item_id;
		
		//JANコードの指定がある場合、既存データを取得
		const refItem = await API.Item.Get(item_id);
		const itemItem = refItem.item;
		const product_no = itemItem?.product_no ?? null;
		const product_name = itemItem?.product_name ?? null;
		const gousu = itemItem?.gousu ?? null;

		//ユーザIDの指定がある場合、既存データを取得
		const user_id = itemsUser.filter(user => user.user_no === user_no)?.[0]?.user_id ?? null;

		new_data.push({
			shipment_id,
			user_no,
			order_date,
			delivery_kbn,
			item_id,
			order_num,
			user_order_no,
			biko,
			order_id,
			pre_order_flag,
			pre_order_request,
			shipping,
			order_no,
			tracking_no,
			shipment_num,
			backorder_num,
			processing_date,
			due_date,
			backorder_days,
			shipping_date,
			user_id,
			product_no,
			product_name,
			gousu,
		});

	}

	return(new_data);
};


export { converShipmentData };