import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import NotFound from '../../../Objects/NotFound';

const Default = () => {

	const history = useHistory();
	const { shipment_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [shipment, setShipment] = useState({});
	
	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.Shipment.Get(shipment_id);
			
			setShipment(ref.item);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleUpdate = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//フォームをロック
		setLock(true);
		
		try {
			
			//フォームオブジェクトの取得
			const formObj = event.currentTarget;
			
			//フォームオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//フォームのバリデーションチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params = {
				shipment_id: shipment.shipment_id,
				user_no: formObj.user_no.value,
				user_id: formObj.user_id.value,
				order_date: formObj.order_date.value,
				delivery_kbn: formObj.delivery_kbn.value,
				item_id: formObj.item_id.value,
				product_no: formObj.product_no.value,
				product_name: formObj.product_name.value,
				gousu: formObj.gousu.value,
				order_num: Number(formObj?.order_num?.value ?? 0),
				user_order_no: formObj.user_order_no.value,
				biko: formObj.biko.value,
				order_id: formObj.order_id.value,
				pre_order_flag: formObj?.pre_order_flag?.value ? 1 : 0,
				pre_order_request: formObj.pre_order_request.value,
				shipping: Number(formObj?.shipping?.value ?? 0),
				order_no: formObj.order_no.value,
				tracking_no: formObj.tracking_no.value,
				shipment_num: Number(formObj?.shipment_num?.value ?? 0),
				backorder_num: Number(formObj?.backorder_num?.value ?? 0),
				processing_date: formObj.processing_date.value,
				due_date: formObj.due_date.value,
				backorder_days: Number(formObj?.backorder_days?.value ?? 0),
				shipping_date: formObj.shipping_date.value,
			};
			//console.log(params);
			
			//更新
			const ref = await API.Shipment.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//フォームをロック
		setLock(true);
		
		try {
			
			//フォームオブジェクトの取得
			const formObj = event.currentTarget;
			
			//フォームオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//フォームのバリデーションチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//削除
			const ref = await API.Shipment.Delete(shipment.shipment_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('実行エラーがありました。(' + ref.error + ')');
			}
			
			//エラー確認
			if(!ref.result){
				throw new Error('実行エラーがありました。');
			}
			
			throw new Error('削除しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/shipment/';
		}
		else{
			setReload(reload+1);
		}
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(Object.keys(shipment).length === 0){
		return (
			<NotFound />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/shipment/">出荷情報</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleUpdate}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'出荷情報ID'} name={'shipment_id'} value={shipment.shipment_id} readOnly={true} />
					<hr/>
					<FormText label={'得意先コード'} name={'user_no'} value={shipment.user_no} required={true} />
					<FormText label={'ユーザーID'} name={'user_id'} value={shipment.user_id} required={true} />
					<FormText label={'受注日'} name={'order_date'} value={shipment.order_date} />
					<FormText label={'直送区分'} name={'delivery_kbn'} value={shipment.delivery_kbn} />
					<FormText label={'JANコード'} name={'item_id'} value={shipment.item_id} required={true} />
					<FormText label={'品番'} name={'product_no'} value={shipment.product_no} required={true} />
					<FormText label={'品名'} name={'product_name'} value={shipment.product_name} required={true} />
					<FormText label={'号数'} name={'gousu'} value={shipment.gousu} />
					<FormText label={'発注数'} name={'order_num'} value={shipment.order_num} type={'number'} />
					<FormText label={'客注番号'} name={'user_order_no'} value={shipment.user_order_no} />
					<FormText label={'備考'} name={'biko'} value={shipment.biko} />
					<FormText label={'web受注番号'} name={'order_id'} value={shipment.order_id} />
					<FormText label={'注残'} name={'pre_order_flag'} value={shipment.pre_order_flag} type={'number'} />
					<FormText label={'注残管理'} name={'pre_order_request'} value={shipment.pre_order_request} type={'number'} />
					<FormText label={'送料'} name={'shipping'} value={shipment.shipping} type={'number'} />
					<FormText label={'受注No.'} name={'order_no'} value={shipment.order_no} required={true} />
					<FormText label={'問い合せNo.'} name={'tracking_no'} value={shipment.tracking_no} />
					<FormText label={'出荷数'} name={'shipment_num'} value={shipment.shipment_num} type={'number'} />
					<FormText label={'注残数'} name={'backorder_num'} value={shipment.backorder_num} type={'number'} />
					<FormText label={'処理日'} name={'processing_date'} value={shipment.processing_date} />
					<FormText label={'納期'} name={'due_date'} value={shipment.due_date} />
					<FormText label={'注残日数'} name={'backorder_days'} value={shipment.backorder_days} type={'number'} />
					<FormText label={'出荷日'} name={'shipping_date'} value={shipment.shipping_date} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >更新</Button>
								　
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
				
				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block="true" disabled={lock} >削除</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
