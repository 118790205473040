import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import Table from '../../../Objects/Table';


const Default = () => {

	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [alert, setAlert] = useState([]);
	const [data, setData] = useState([]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.Param.Scan();

			//console.log(JSON.stringify(ref));

			const newData = JSON.parse(ref.items?.find((v) => v.name === 'top-images')?.value ?? '[]');

			//console.log(newData);
			
			//データ取得
			setData(newData);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleSubmit = async (event) => {
		
		const fileToBase64 = (file) => {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			});
		};

		const fetchData = async () => {
			try {
				const ref = await API.Param.Scan();
				const checkData = JSON.parse(ref.items?.find((v) => v.name === 'top-images')?.value ?? '[]');
		
				// checkData.lengthとdata.lengthを比較
				if (checkData.length !== data.length) {
					//console.log('件数が異なるため、処理を停止します。');
					clearInterval(intervalId); // 定期実行を停止
					return; // 関数の実行をここで終了
				}
		
				console.log(checkData); // 件数が同じ場合は処理を続行
			} catch (err) {
				console.error('エラーが発生しました:', err.message);
			}
		};

		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();
		
		//Formをロック
		setLoad(true);
		
		try {
			
			
			//ファイルオブジェクト
			const file = event.target.files[0];

			//ファイルが選択されなかった場合
			if(!file){ return(0); }
			
			//ファイル名
			const file_name = file.name;
			
			const size = file.size;
			
			//ファイルサイズの確認
			if(size.size > 524288){
				throw new Error('ファイルサイズが512KBを超えています。(' + size.size.toLocaleString() + 'Bytes)');
			}

			const file_base64 = await fileToBase64(file);

			if(file_base64.length >= 1024 * 1024){
				throw new Error('登録出来るファイルサイズを超えています。(' + file_base64.length.toLocaleString() + 'Bytes)');
			}

			const ref = await API.Upload.Add(file_base64, file_name);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}						
		}
		catch(err){
			setAlert([err.message]);
		}
		
		//フォームリセット
		//document.upload.file_select.value='';

		const intervalId = setInterval(fetchData, 10000);

		//ロック解除
		setLoad(false);

		handleReload();
	};

	const handleDelete = async (name) => {

		try {

			const newData = [];

			for(const item of data){
				if(item.name !== name){
					newData.push(item);
				}
			}

			newData.sort((a, b) => a.sort - b.sort);

			//sortを再設定
			for(let i=0;i<newData.length;i++){
				newData[i].sort = i+1;
			}
			
			//更新
			const params ={
				name : 'top-images',
				value : JSON.stringify(newData),
			};
			
			//更新
			const ref = await API.Param.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}						
		}
		catch(err){
			setAlert([err.message]);
		}
		handleReload();

	};

	const handleSortChange = async (name, sort) => {

		try {

			const newData = JSON.parse(JSON.stringify(data));

			// sortが0以下の場合は1に設定
			sort = Math.max(sort, 1);

			// 配列内の最大のsort値を見つける
			const maxSort = Math.max(...newData.map(item => item.sort));
			// sortが配列の最大sortより大きい場合、最大sortに設定
			sort = Math.min(sort, maxSort);

			const itemIndex = newData.findIndex(item => item.name === name);
			if (itemIndex === -1) throw new Error('指定されたアイテムが見つかりません。');
	
			// 既存のsort値を取得
			const currentItem = newData[itemIndex];
			const currentSort = currentItem.sort;
	
			// 新しいsort値を設定
			currentItem.sort = sort;
	
			// 他のアイテムのsort値を調整
			newData.forEach(item => {
				if (item.name !== name) {
					if (currentSort < sort && item.sort > currentSort && item.sort <= sort) {
						item.sort--;
					} else if (currentSort > sort && item.sort < currentSort && item.sort >= sort) {
						item.sort++;
					}
				}
			});
	
			// sort値に基づいて配列を再ソート
			newData.sort((a, b) => a.sort - b.sort);

			//更新
			const params ={
				name : 'top-images',
				value : JSON.stringify(newData),
			};


			//更新
			const ref = await API.Param.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			handleReload();
		}
		catch(err){
			setAlert([err.message]);
		}
	};

	const handleClose = () => {
		setAlert([]);
		setReload(reload+1);
	};
	
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const hour		 = ('00' + dateObj.getHours().toString()).slice(-2);
			const minute		 = ('00' + dateObj.getMinutes().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};

	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={()=>handleDelete(row.name)}>
					削除
				</Button>
			</div>
		);
	};

	const formatSort = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()}
				　[ <a href={'#' + row.name} onClick={() => handleSortChange(row.name, Number(cell + 1))}>＋</a>
				 | 
				 <a href={'#' + row.name} onClick={() => handleSortChange(row.name, Number(cell - 1))}>－</a> ]
			</div>
		);
	};

	const columns = [
		{
			dataField: 'sort',
			text: '順番',
			sort: true,
			formatter: formatSort,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'original',
			text: 'ファイル名',
			sort: false,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'date',
			text: '登録日時',
			sort: false,
			formatter: formatDate,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '削除',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/top-images/">トップ画像</Breadcrumb.Item>
				</Breadcrumb>

				<Row>
					<Col sm={6}>
						<p><big>トップ画像</big></p>
					</Col>
					<Col sm={6} className="text-right">
						<Button variant="dark" onClick={ handleReload }>
							<FaRedo />
						</Button>
					</Col>
				</Row>
				<hr/>
				<Form name="upload">
					<label htmlFor="csv_upload" className="btn btn-dark btn-block">
						画像の追加（ファイルのアップロードには1、2分程度かかる場合があります。）<br/>
						<Form.Control type="file" name={'file_select'} accept="image/png, image/jpeg" onChange={handleSubmit} id="csv_upload" style={{display:'none'}}/>
					</label>
				</Form>
				<hr/>
				<Row style={{ marginTop: 20 }} >
				<Col sm={12}>
					<Table keyField={'name'} data={data} columns={columns} sort={ { field: 'sort', order: 'asc'} }/>
				</Col>
			</Row>
				
			</Container>
		);
	}
};

export default withRouter(Default);
