import { useState, useEffect } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Breadcrumb,
	Image,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const { category1_code } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [category1_name, setCategory1Name] = useState('');
	
	useEffect(() => {
	
		const getData = async (category1_code) => {
			
			const ref = await API.Category1.Get(category1_code);
			//console.log(ref);
			
			setCategory1Name(ref.item.category1_name);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData(category1_code);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);

	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params ={
				category1_code : category1_code,
				category1_name : category1_name,
				category2_code : formObj.category2_code.value,
				category2_name : formObj.category2_name.value,
			};
			//console.log(params);
			
			//更新
			const ref = await API.Category2.Put(params);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//フォームリセット
			formObj.reset();
			
			throw new Error('追加しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		setReload(reload+1);
		//window.location.reload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href={'/admin/category2/' + category1_code + '/'}>子カテゴリー情報</Breadcrumb.Item>
					<Breadcrumb.Item active>新規登録</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>新規登録</big></p>
						</Col>
					</Row>
					<hr/>
					<FormText label={'親カテゴリーコード'} name={'category1_code'} value={category1_code} readOnly={true} />
					<FormText label={'親カテゴリー名'} name={'category1_name'} value={category1_name} readOnly={true} />
					<hr/>
					<FormText label={'子カテゴリーコード'} name={'category2_code'} required={true} />
					<FormText label={'子カテゴリー名'} name={'category2_name'} required={true} />
					<hr/>
					
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
								<Button variant="dark" type="submit" block="true" disabled={lock} >登録</Button>
								　
								<Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
