import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { withRouter, useHistory } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [modal, setModal] = useState([]);
	const [selectIDs, setSelectIDs] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.WishList.Query();

				const newData = [];
				for(const item of (ref?.items ?? [])){

					console.log(item);

					if(!item?.item_id){
						continue;
					}

					const ref = await API.Item.Get(item.item_id);

					const newItem = ref?.item ?? {};

					if(Object.keys(newItem).length){
						newData.push(newItem);
					}
				}

				//データセット
				setData(newData);
				
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleReload = () => {
		setReload(reload+1);
	};
		
	const handleCart = async (item) => {

		//Formをロック
		setLock(true);
		
		try {

			const params ={
				item_id : item.item_id,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : Number(item?.price ?? 0),
				unit_price : Number(item?.unit_price ?? 0),
				piece : Number(item?.minimum_unit ?? 1),
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_flag : item.pre_order_flag,
				pre_order_request : item?.pre_order_flag ? 1 : 0,
				stock_flag : item.stock_flag,
				message : '',
			};
			//console.log(params);

			//削除
			const ref = await API.Cart.Add(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			setModal(['商品をカートに入れました。']);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (item_id) => {

		//Formをロック
		setLock(true);
		
		try {
			//削除
			const ref = await API.WishList.Delete(item_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		setModal([]);
		setReload(reload+1);
	};
	
	const formatItemLink = (cell, row) => {
		return (
			<div>
				<a href={'/item/' + row.item_id}>{cell}</a>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	const formatCart = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleCart(row)} disabled={lock} >
					カートに入れる
				</Button>
			</div>
		);
	};
	
	const formatDelete = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
					削除
				</Button>
			</div>
		);
	};
	
	const columns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatItemLink,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
/*
		{
			dataField: 'unit_price',
			text: '一般卸価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
*/
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'genre_name',
			text: 'ジャンル名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'genre_code',
			text: 'ジャンルコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'catalog_no',
			text: 'カタログページ',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'keyword',
			text: '検索用語',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'sort',
			text: '並び順',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'boxed_num',
			text: '内容量',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'url',
			text: 'URL',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'pre_order_flag',
			text: '注残',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: (cell) => cell ? '×' : '○',
		},
		{
			dataField: 'stock_flag',
			text: '在庫',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'dummy1',
			text: 'カートに入れる',
			sort: false,
			headerClasses: 'text-center',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatCart,
		},
		{
			dataField: 'dummy2',
			text: '削除',
			sort: false,
			headerClasses: 'text-center',
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			formatter: formatDelete,
		},
	];

	const mobileColumns = [
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>JAN</td>
							<td>{row.item_id}</td>
						</tr>
						<tr>
							<td>在庫</td>
							<td>{row.stock_flag}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						<tr>
							<td>価格</td>
							<td>{Number(row.price ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td colSpan="2">
								<Button variant="dark" type="button" onClick={() => handleCart(row)} disabled={lock} >
									カートに入れる
								</Button>　
								<Button variant="dark" type="button" onClick={() => history.push('/item/' + row.item_id)} disabled={lock} >
									詳細
								</Button>　
								<Button variant="dark" type="button" onClick={() => handleDelete(row.item_id)} disabled={lock} >
									削除
								</Button>
							</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />

				<Modal show={modal.length ? true : false} onHide={handleClose} centered>
					<Modal.Body>
						{modal.map((msg, index) => {return (<p key={index} style={{margin: "0px"}}>{msg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							商品選択を続ける
						</Button>
						<Button variant="primary" onClick={ () => { window.location.href = '/cart/'; } }>
							商品カートに移動する
						</Button>
					</Modal.Footer>
				</Modal>

				<Row>
					<Col sm={12}>
						<h4>購入品リスト</h4>
					</Col>
				</Row>
				<Row style={{display : isMobile ? 'none' : 'block'}}>
					<Col sm={12}>
						<Form>
							<Row style={{ marginTop: 20 }}>
								<Col sm={12} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
									<br/>
									　
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'item_id'} data={data} columns={isMobile ? mobileColumns : columns} checkbox={false} selectIDs={selectIDs} setSelectIDs={setSelectIDs} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
