import {
	Navbar,
	Nav,
	NavDropdown,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import Version from './Version';

const Default = (props) => {

	const history = useHistory();

	const handleClick = (uri) => {
		if(uri === '/admin/'){
			window.location.href = '/admin/';
		}
		else{
			history.push(uri);
		}
	};
	return (
		<div>
			<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="no_print">
				<Navbar.Brand href="#home"><img src="/img/logo.jpg" width={140} alt={'logo'} className="d-inline-block align-top" /></Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav onSelect={handleClick} className="mr-auto">
						<NavDropdown title="商品検索" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/item/"}>商品検索</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/product/"}>品番検索</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/category/"}>カテゴリ検索</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/genre/"}>ジャンル検索</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/bulk/"}>一括発注</NavDropdown.Item>
							{/* Ver.3.30
								<NavDropdown.Item eventKey={"/scan/"}>バーコード発注</NavDropdown.Item>
							*/}
						</NavDropdown>
						<Nav.Link eventKey={"/order-history/"}>発注履歴</Nav.Link>
						<Nav.Link eventKey={"/wish-list/"}>購入品リスト</Nav.Link>
						<Nav.Link eventKey={"/stock-out/"}>欠品情報</Nav.Link>
						{/* Ver.3.30
							<Nav.Link eventKey={"/shipment/"}>出荷状況</Nav.Link>
							<Nav.Link eventKey={"/back-order/"}>注残管理</Nav.Link>
						*/}
					</Nav>
					<Nav onSelect={handleClick}>
						<Nav.Link eventKey={"/cart/"}>商品カート</Nav.Link>
						<NavDropdown title="ログイン情報" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey={"/account/user/"}>お客様情報</NavDropdown.Item>
							<NavDropdown.Item eventKey={"/account/"}>アカウント情報</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/admin/"} disabled={props.admin === true ? false : true}>管理画面</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item eventKey={"/signout/"}>ログオフ</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link eventKey={"/"} style={{color:'white'}}>{Version}</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default withRouter(Default);

