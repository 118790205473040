
const AwsExports = {
    Auth: {
        identityPoolId: 'ap-northeast-1:406f388b-b8cf-414c-85d2-bcb2fceb8880',
        userPoolId: 'ap-northeast-1_fAougsNJM',
        userPoolWebClientId: '5fkj16bs9rusn9tslsedvodoab',
        region: 'ap-northeast-1',
    },
    API: {
        'aws_appsync_graphqlEndpoint': 'https://lu57yklthzaqna4fy7mzwou6ca.appsync-api.ap-northeast-1.amazonaws.com/graphql',
        'aws_appsync_region': 'ap-northeast-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    },
};

export default AwsExports;
