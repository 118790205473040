import { useState, useEffect  } from 'react';
import {
	Container,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import API from '../@onocomm-system';

const Default = () => {

	const [data, setData] = useState([]);
	
	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.Param.Scan();

			//console.log(JSON.stringify(ref));

			const newData = JSON.parse(ref.items?.find((v) => v.name === 'top-images')?.value ?? '[]');

			newData.sort((a, b) => a.sort - b.sort);

			//console.log(newData);
			
			//データ取得
			setData(newData);

		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	


	return (
		<div>
			<Container>
				<div className="page-header" style={{marginTop: '50px'}}>
					{(data.length !== 0) && data?.map((x, i) => (
						<div key={i}>
							<img src={'/top-images/' + x.name} alt={x.original} />
							<br/>
						</div>
					))}
				</div>
			</Container>
		</div>
	);
};

export default withRouter(Default);
