import { useState, useEffect } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Breadcrumb,
	Modal,
	ProgressBar,
} from 'react-bootstrap';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../../Objects/Modal';
import { converShipmentData } from './extra/shipment';


const Default = () => {

	const history = useHistory();
	const { section } = useParams();
	const [load, setLoad] = useState(true);
	const [csv_data, setCsvData] = useState([]);
	const [column_data, setColumnData] = useState([]);
	const [final_check, setFinalCheck] = useState([]);
	const [exec, setExec] = useState(false);
	const [progress, setProgress] = useState(0);
	const [total, setTotal] = useState(0);
	const [num, setNum] = useState(0);
	
	useEffect(() => {
	
		const csvUpload = async (column, data) => {
		
			const putCSVData = async (params) => {
				
				//console.log(params);
				
				//変数
				let execute_flag = 'UNKOWN';
				let result_flag = false;
				let message = '';
				
				try {
				
					//既存データの確認
					const exist = await API[section.slice( 0, 1 ).toUpperCase() + section.slice( 1 )].Get(params[column[0].dataField]);
					
					//実行種別の判定
					if(Object.keys(exist.item).length === 0){

						//実行フラグ
						execute_flag = 'PUT';

						//DynamoDB PUT
						const ref = await API[section.slice( 0, 1 ).toUpperCase() + section.slice( 1 )].Put(params);
						
						//結果取得
						result_flag = ref.result;
						message = ref.error ? ref.error : '正しくデータ登録を完了しました。';
					}
					else{

						//実行フラグ
						execute_flag = 'UPDATE';
						
						//DynamoDB UPDATE
						const ref = await API[section.slice( 0, 1 ).toUpperCase() + section.slice( 1 )].Update(params);
						
						//結果取得
						result_flag = ref.result;
						message = ref.error ? ref.error : '正しくデータ登録を完了しました。';
					}
					
					//結果
					return({
						execute_flag: execute_flag,
						result_flag: result_flag,
						message: message,
					});
				}
				catch(err){
					console.log(err);
					//結果
					return({
						execute_flag: execute_flag,
						result_flag: result_flag,
						message: JSON.stringify(err),
					});
				}
			};
			
			const putCSVLog = async (params) => {
			
				try {
					//更新
					await API.CSVLog.Put(params);
					
				}
				catch(err){
					console.log(err);
				}
			
			};
			
			//セクションを確認
			if(!section) { return(0); }

			//データ変換
			let new_data = [];
			if(section === 'shipment'){
				new_data = await converShipmentData(data);
			}
			else{
				new_data = structuredClone(data);
			}
			//console.log(new_data);
			
			//CSV実行IDを生成
			const csv_id = section + '-' + Math.floor((new Date()).getTime() / 1000).toString();
			//console.log(csv_id);
			
			//件数
			let i = 1;

			//実行
			for(const item of new_data){
			
				//wait
				//const sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));
				//await sleep(1);

				//ロデータアップロード
				const result = await putCSVData(item);
				
				//ログ記録
				await putCSVLog({
					csv_id: csv_id,
					num: i,
					target_section: section,
					target_id_name: column[0].dataField,
					target_id_value: item[column[0].dataField],
					target_text: JSON.stringify(item),
					execute_flag: result.execute_flag,
					result_flag: result.result_flag,
					message: result.message,
				});
				
				//進捗
				setProgress(Math.floor(i / data.length * 100));
				setNum(i);

				//count
				i++;
			}
			
			//ストレージ
			const storage = sessionStorage;
			
			//データ削除
			storage.removeItem('csv_data');
			storage.removeItem('csv_column_data');
			storage.removeItem('csv_select_ids');
			storage.removeItem('csv_final_check');
			
			//完了
			window.location.href = '/admin/csv-upload/csv-log/' + csv_id + '/';
		};
		
		//ストレージ
		const storage = sessionStorage;
		
		//CSVデータ
		const csvData = storage.getItem('csv_data') ? JSON.parse(storage.getItem('csv_data')) : [];

		//Columnデータ
		const columnData = storage.getItem('csv_column_data') ? JSON.parse(storage.getItem('csv_column_data')) : [];
		
		//セレクトIDデータ
		const selectIDs = storage.getItem('csv_select_ids') ? JSON.parse(storage.getItem('csv_select_ids')) : [];
		
		//最終チェック
		const finalCheck = storage.getItem('csv_final_check') ? JSON.parse(storage.getItem('csv_final_check')) ? true : false : false;
		
		//新しいデータ
		const newCSVData = [];
		
		//チェックされたデータだけを抜き出し
		for(const item of csvData){
			if(selectIDs.length){
				for(const id of selectIDs){
					if(item[columnData[0].dataField] === id){
						newCSVData.push(item);
					}
				}
			}
			else{
				newCSVData.push(item);
			}
		}
		
		//保存
		setCsvData(newCSVData);
		setColumnData(columnData);
		setFinalCheck(finalCheck);
		setTotal(newCSVData.length);
		
		//表示開始
		setLoad(false);
		
		//処理表示開始
		setExec(true);

		//処理開始
		csvUpload(columnData, newCSVData);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const handleClose = () => {
		//console.log(true);
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(!section || column_data.length === 0){
		return (
			<ModalAlert data={['CSV種別が不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else if(csv_data.length === 0){
		return (
			<ModalAlert data={['CSVデータが不正です']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else if(final_check !== true){
		return (
			<ModalAlert data={['最終確認を行ってください']} onClick={ ()=>{history.push('/admin/csv-upload/');} } />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<Modal show={exec} onHide={handleClose} centered>
					<Modal.Body className={'text-center'}>
						現在、CSVデータのアップロード処理中です。<br/>
						処理中はブラウザを操作しないようにしてください。<br/>
						ブラウザをリロードや、閉じたりすると処理が中断します。<br/>
						<p>現在の処理件数 {num.toLocaleString()} 件 / {total.toLocaleString()} 件</p>
						<ProgressBar now={progress} label={`${progress}%`} />
					</Modal.Body>
				</Modal>

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/csv-upload/">CSVアップロード</Breadcrumb.Item>
					<Breadcrumb.Item href={'/admin/csv-upload/' + section + '/'}>CSVファイルの選択</Breadcrumb.Item>
					<Breadcrumb.Item active>アップロード内容の確認</Breadcrumb.Item>
					<Breadcrumb.Item active>最終確認</Breadcrumb.Item>
					<Breadcrumb.Item active>取り込み実行</Breadcrumb.Item>
				</Breadcrumb>

				<Row>
					<Col sm={6}>
						<p><big>取り込み実行</big></p>
					</Col>
				</Row>
				<hr/>
				<p>
					※現在、CSVデータのアップロード処理中です。<br/>
					※処理中はブラウザを操作しないようにしてください。<br/>
					※ブラウザをリロードや、閉じたりすると処理が中断します。<br/>
				</p>
				<hr/>
			</Container>
		);
	}
};

export default withRouter(Default);
