import { useState } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = () => {

	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
		
	const handleSubmit = async (event) => {
		
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}

			//発注指示のテキストデータを取得
			const item_data_text = formObj?.item_data?.value ?? '';

			//データの指定がない場合はエラー
			if(item_data_text === ''){
				throw new Error('データの指定がありません');
			}

			//データを改行で分割
			const item_data = item_data_text.replace(/ /g, '').split('\n');

			//オブジェクトデータの初期化
			const checkData = [];

			//1行ずつテキストデータを処理をしてカンマ区切りでデータを取得
			for(const item of item_data){
				const columns = item.split(',');
				const item_id = columns?.[0] ?? null;
				const piece = columns?.[1] ?? null;
				const pre_order_request = columns?.[2] ? 1 : 0;
				if(!item_id){
					continue;
				}
				checkData.push({
					item_id,
					piece,
					pre_order_request,
				});
			}
			//console.log(checkData);

			if(!checkData.length){
				throw new Error('データ形式が正しくありません');
			}

			//JANコードの重複チェック
			const itemIds = checkData.map(item => item.item_id); // item_id の配列を取得
			const duplicateIds = itemIds.filter((id, index) => itemIds.indexOf(id) !== index); // 重複する item_id をフィルター

			if (duplicateIds.length > 0) {
				throw new Error('JANコードが重複しています:' + duplicateIds?.[0]);
			}

			//カート処理用のデータを作成
			const newCatdData = [];
			
			//商品マスタとの照合
			for(const checkItem of checkData){
				const item_id = checkItem.item_id;
				let piece = checkItem.piece;
				const pre_order_request = checkItem.pre_order_request;
				const ref = await API.Item.Get(item_id);
				const item = ref.item;
				if(Object.keys(item).length === 0){
					throw new Error('商品が見つかりません:' + item_id);
				}
				if(Number(piece) < Number(item.minimum_unit)){
					piece = item.minimum_unit;
				}
				if(Number(piece) % Number(item.minimum_unit) !== 0){
					throw new Error('数量は' + item.minimum_unit + 'の単位で指定してください。');
				}
				newCatdData.push({
					item_id : item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					price : Number(item?.price ?? 0),
					unit_price : Number(item?.unit_price ?? 0),
					piece : Number(piece ?? 1),
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_flag : item.pre_order_flag,
					pre_order_request : item?.pre_order_flag ? 1 : pre_order_request ? 1 : 0,
					stock_flag : item.stock_flag,
					message : '',
				});
			};
			//console.log(newCatdData);

			for(const param of newCatdData){
				//カートに追加
				const ref = await API.Cart.Add(param);
				//console.log(ref);
				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + ref.error + ')');
				}
			}

			throw new Error('商品をカートに入れました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};	

	const handleClose = () => {
		if(alert?.[0] === '商品をカートに入れました。'){
			setAlert([]);
			window.location.href = '/cart';
		}
		setAlert([]);
	};
	
	return (
		<Container style={{ marginTop: 50, marginBottom: 200 }}>
			<ModalAlert data={alert} onClick={handleClose} />

			<Form name="form" onSubmit={handleSubmit}>

				<Row>
					<Col sm={6}>
						<p><big>一括発注</big></p>
					</Col>
					<Col sm={12}>
						{/* Ver.3.30
							<p>
								半角カンマ区切りでJANコード、個数、注残を指定してください。個数と注残は省略可能です。
								1行に1商品ずつ指定が出来ます。複数の商品を指定する場合は改行してください。
								{'例：JANコード[[,個数（指定がない場合は最少出荷単位）],注残（0か1か）]'}
							</p>
						*/}
						<p>
							半角カンマ区切りでJANコード、個数を指定してください。個数は省略可能です。
							1行に1商品ずつ指定が出来ます。複数の商品を指定する場合は改行してください。
							{'例：JANコード[,個数（指定がない場合は最少出荷単位）]'}
						</p>
					</Col>
				</Row>
				{/* Ver.3.30
					<textarea name="item_data" className="form-control" placeholder={'4953873000829,5,0'} rows={15} required={true}>
				*/}
				<textarea name="item_data" className="form-control" placeholder={'4953873000829,5'} rows={15} required={true}>
				</textarea>				
				<Row style={{ marginTop: 10 }}>
					<Col>
					        <Button variant="dark" type="submit" block="true" disabled={lock} >カートに入れる</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default withRouter(Default);
