import User from './API/User';
import Item from './API/Item';
import Product from './API/Product';
import Genre from './API/Genre';
import Category1 from './API/Category1';
import Category2 from './API/Category2';
import Cart from './API/Cart';
import Order from './API/Order';
import WishList from './API/WishList';
import CSVLog from './API/CSVLog';
import Param from './API/Param';
import Upload from './API/Upload';
import Shipment from './API/Shipment';

const API = {
	User         : User,
	Item         : Item,
	Product      : Product,
	Genre        : Genre,
	Category1    : Category1,
	Category2    : Category2,
	Cart         : Cart,
	Order        : Order,
	WishList     : WishList,
	CSVLog       : CSVLog,
	Param		 : Param,
	Upload		 : Upload,
	Shipment     : Shipment,
};


export default API;
