import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Genre = {};

Genre.Get = async (genre_code) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getGenre, { genre_code: genre_code }));
		const item = ref.data.getGenre;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Genre.Scan = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanGenre));
		let items = ref.data.scanGenre.items;
		while (ref.data.scanGenre.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanGenre, { nextToken: ref.data.scanGenre.nextToken }));
			items = items.concat(ref.data.scanGenre.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Genre.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putGenre, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Genre.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateGenre, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Genre.Delete = async (genre_code) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteGenre, { genre_code: genre_code }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Genre;


