import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const WishList = {};

WishList.Get = async (item_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getWishList, { item_id: item_id }));
		const item = ref.data.getWishList;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

WishList.Query = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryWishList));
		let items = ref.data.queryWishList.items;
		while (ref.data.queryWishList.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryWishList, { nextToken: ref.data.queryWishList.nextToken }));
			items = items.concat(ref.data.queryWishList.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

WishList.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putWishList, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

WishList.Delete = async (item_id) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteWishList, { item_id: item_id }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default WishList;


