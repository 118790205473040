import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { FormText, FormSelect } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';
import NotFound from '../../../Objects/NotFound';


const Default = () => {

	const history = useHistory();
	const { item_id } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [item, setItem] = useState({});
	
	useEffect(() => {
	
		const getData = async () => {
			
			const ref = await API.Item.Get(item_id);
			//console.log(ref);
			
			setItem(ref.item);
			
			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	const handleReload = () => {
		setLoad(true);
		setReload(reload+1);
		setAlert([]);
	};

	const handleUpdate = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params ={
				item_id : item.item_id,
				product_no : formObj.product_no.value,
				product_name : formObj.product_name.value,
				gousu : formObj.gousu.value,
				price : Number(formObj?.price?.value ?? 0),
				unit_price : Number(formObj?.unit_price?.value ?? 0),
				minimum_unit :  Number(formObj?.minimum_unit?.value ?? 0),
				category1_name : formObj.category1_name.value,
				category1_code : formObj.category1_code.value,
				category2_name : formObj.category2_name.value,
				category2_code : formObj.category2_code.value,
				genre_name : formObj.genre_name.value,
				genre_code : formObj.genre_code.value,
				catalog_no : formObj.catalog_no.value,
				keyword : formObj.keyword.value,
				sort : Number(formObj?.sort?.value ?? 0),
				boxed_num : Number(formObj?.boxed_num?.value ?? 0),
				url : formObj.url.value,
				pre_order_flag : formObj?.pre_order_flag?.value ? 1 : 0,
				stock_flag : formObj.stock_flag.value,
			};
			//console.log(params);
			
			//更新
			const ref = await API.Item.Update(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			throw new Error('更新しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleDelete = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//削除
			const ref = await API.Item.Delete(item.item_id);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('実行エラーがありました。(' + ref.error + ')');
			}
			
			//エラー確認
			if(!ref.result){
				throw new Error('実行エラーがありました。');
			}
			
			throw new Error('削除しました。');
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const dateShaping = (datetime) => {
		const dateObj      = new Date(Number(datetime * 1000));
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour         = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute       = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const second       = ('00' + dateObj.getSeconds().toString()).slice(-2);
		return(year + '年' + month + '月' + day + '日' + hour + '時' + minute + '分' + second + '秒');
	
	};
	
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/item/';
		}
		else{
			setReload(reload+1);
		}
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else if(Object.keys(item).length === 0){
		return (
			<NotFound />
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/item/">商品情報</Breadcrumb.Item>
					<Breadcrumb.Item active>詳細</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleUpdate}>

					<Row>
						<Col sm={6}>
							<p><big>詳細</big></p>
						</Col>
						<Col sm={6} className="text-right">
							<Button variant="dark" onClick={ handleReload }>
								<FaRedo />
							</Button>
						</Col>
					</Row>
					<hr/>
					<FormText label={'登録日時'} name={'date_add'} value={dateShaping(item.date_add)} readOnly={true} />
					<FormText label={'JANコード'} name={'item_id'} value={item.item_id} readOnly={true} />
					<hr/>
					<FormText label={'品番'} name={'product_no'} value={item.product_no} required={true} />
					<FormText label={'品名'} name={'product_name'} value={item.product_name} required={true} />
					<FormText label={'号数'} name={'gousu'} value={item.gousu} />
					<FormText label={'ﾒｰｶｰ希望本体価格'} name={'price'} value={item.price} type={'number'} />
					<FormText label={'一般卸価格'} name={'unit_price'} value={item.unit_price} type={'number'} />
					<FormText label={'最少出荷単位'} name={'minimum_unit'} value={item.minimum_unit} type={'number'} />
					<FormText label={'親カテゴリー名'} name={'category1_name'} value={item.category1_name} required={true} />
					<FormText label={'親カテゴリーコード'} name={'category1_code'} value={item.category1_code} required={true} />
					<FormText label={'子カテゴリー名'} name={'category2_name'} value={item.category2_name} required={true} />
					<FormText label={'子カテゴリーコード'} name={'category2_code'} value={item.category2_code} required={true} />
					<FormText label={'ジャンル名'} name={'genre_name'} value={item.genre_name} required={true} />
					<FormText label={'ジャンルコード'} name={'genre_code'} value={item.genre_code} required={true} />
					<FormText label={'カタログページ'} name={'catalog_no'} value={item.catalog_no} />
					<FormText label={'検索用語'} name={'keyword'} value={item.keyword} />
					<FormText label={'並び順'} name={'sort'} value={item.sort} type={'number'} />
					<FormText label={'内容量'} name={'boxed_num'} value={item.boxed_num} type={'number'} />
					<FormText label={'URL'} name={'url'} value={item.url} />
					<FormText label={'注残'} name={'pre_order_flag'} value={item.pre_order_flag} type={'number'} />
					<FormSelect label={'在庫'} name={'stock_flag'} value={item.stock_flag} data={ [ '○', '×'] } required={true} />
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >更新</Button>
								　
						        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
				
				<Form onSubmit={handleDelete}>
				<Row style={{ marginTop: 50 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="danger" type="submit" block="true" disabled={lock} >削除</Button>
					</Col>
				</Row>
				</Form>

			</Container>
		);
	}
};

export default withRouter(Default);
