import { useState, useEffect, useCallback } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Form,
	Button,
	Badge,
	Modal,
} from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, useParams,  withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import CSVDownload from '../../Objects/CSVDownload';
import { textFilter, numberFilter, selectFilter } from 'react-bootstrap-table2-filter';


const Default = () => {

	const history = useHistory();
	const { flag, target, sub_target } = useParams();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [modal, setModal] = useState([]);
	const [category1, setCategory1] = useState([]);
	const [category2, setCategory2] = useState([]);
	const [category1_select, setCategory1Select] = useState([]);
	const [category2_select, setCategory2Select] = useState([]);
	const [selectIDs, setSelectIDs] = useState([]);
	const [search, setSearch] = useState('');
	const [csv1, setCSV1] = useState([]);
	const [csvName1, setCsvName1] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [showFilter, setShowFilter] = useState(sessionStorage.getItem("showFilter") === 'true' ? true : false);

	//データ検索
	const createSearchData = useCallback(({data, category1_select, category2_select, search}) => {
		
		let new_data;

		//カテゴリボタン検索
		if(category1_select.length){
			new_data = data.filter(item => {
				let flag = false;
				//選択されている親カテゴリを確認
				for(const select1 of category1_select){
					//選択されている親カテゴリと同じデータを対象とする
					if(item.category1_code === select1.category1_code){
						//選択されている親カテゴリの中で子カテゴリが選択されているものを対象とする
						if(category2_select.filter(select2=>select2.category1_code===select1.category1_code).length){
							//選択されている親カテゴリの中で子カテゴリが選択されているものは子カテゴリが一致するもののみ対象
							if(category2_select.filter(select2=>select2.category1_code===item.category1_code&&select2.category2_code===item.category2_code).length){
								flag = true;
								break;	
							}
						}
						//選択されている親カテゴリの中で子カテゴリが選択されていないものは絞り込みの対象外にする
						else{
							flag = true;
							break;
						}
					}
				}
				return(flag);
			});
		}
		else{
			new_data = data.concat();
		}

		//検索文字オブジェクト
		const matchString = new RegExp(search);
		
		//検索
		const search_data = new_data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		//console.log(search_data);
		
		return(search_data);

	}, []);

	//小カテゴリ作成
	const createCategory2 = useCallback(async({category1_select}) => {
		const newCategory2 = [];
		for(const item of category1_select){
			const ref = await API.Category2.Query(item.category1_code);
			for(const x of ref.items){
				newCategory2.push(x);
			}
		}
		return(newCategory2);
	}, []);

	const handleReload = () => {
		setReload(reload+1);
	};

	useEffect(() => {
	
		const getData = async () => {

			//console.log(flag, target, sub_target);
			
			//商品データの取得
			const refItem = flag === 'product'   ? await API.Item.QueryProduct(target) :
							flag === 'genre'     ? await API.Item.QueryGenre(target) :
							flag === 'category' ? await API.Item.QueryCategory(target, sub_target) : await API.Item.Scan();

			const data = refItem?.items ?? [];
			
			//データセット
			setData(data);

			//大カテゴリの取得
			const refCategory1 = await API.Category1.Scan();
			const category1 = refCategory1.items;
			
			//データセット
			setCategory1(category1);

			//検索条件の呼び出し
			const category1_select = JSON.parse(sessionStorage.getItem("category1_select") ?? '[]');
			const category2_select = JSON.parse(sessionStorage.getItem("category2_select") ?? '[]');
			const search = sessionStorage.getItem("search") ?? '';

			//データセット
			setCategory1Select(category1_select);
			setCategory2Select(category2_select);
			setSearch(search);

			//大カテゴリの取得
			const category2 = await createCategory2({ category1_select });

			//データセット
			setCategory2(category2);

			//データ検索
			const view = createSearchData({ data, category1_select, category2_select, search});

			setView(view);

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
	},[
		reload,
		flag,
		target,
		sub_target,
		createCategory2,
		createSearchData,
	]);

	useEffect(() => {

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleSearch = (event) => {
		try{
			event.preventDefault();
			event.stopPropagation();
		}
		catch(err){
			//console.log(err);
		}

		//データ検索
		const newData = createSearchData({ data, category1_select, category2_select, search});

		//データセット
		setView(newData);

	};

	const handleSubmit = async (event) => {
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {

			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//対象商品の取得
			const items = data.filter(x => selectIDs.includes(x.item_id));
			
			//カートに入れる処理
			for(const item of items){
				const params ={
					item_id : item.item_id,
					product_no : item.product_no,
					product_name : item.product_name,
					gousu : item.gousu,
					price : Number(item?.price ?? 0),
					unit_price : Number(item?.unit_price ?? 0),
					piece : Number(item?.minimum_unit ?? 1),
					minimum_unit : item.minimum_unit,
					category1_name : item.category1_name,
					category1_code : item.category1_code,
					category2_name : item.category2_name,
					category2_code : item.category2_code,
					genre_name : item.genre_name,
					genre_code : item.genre_code,
					catalog_no : item.catalog_no,
					keyword : item.keyword,
					sort : item.sort,
					boxed_num : item.boxed_num,
					url : item.url,
					pre_order_flag : item.pre_order_flag,
					pre_order_request : item?.pre_order_flag ? 1 : 0,
					stock_flag : item.stock_flag,
					message : '',
				};
				//console.log(params);
				
				//更新
				const ref = await API.Cart.Add(params);
				//console.log(ref);

				//エラー確認
				if(ref.error){
					throw new Error('入力エラーがありました。(' + ref.error + ')');
				}
			}
			
			//モーダル出現
			if(items.length){
				//setSelectIDs([]);
				setModal(['商品をカートに入れました。']);
			}
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		setModal([]);
		setSelectIDs([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/item/';
		}
		else{
			setReload(reload+1);
		}
	};
	
	const handleCart = async (item) => {

		//Formをロック
		setLock(true);
		
		try {

			const params ={
				item_id : item.item_id,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : Number(item?.price ?? 0),
				unit_price : Number(item?.unit_price ?? 0),
				piece : Number(item?.minimum_unit ?? 1),
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_flag : item.pre_order_flag,
				pre_order_request : item?.pre_order_flag ? 1 : 0,
				stock_flag : item.stock_flag,
				message : '',
			};
			//console.log(params);

			//削除
			const ref = await API.Cart.Add(params);
			//console.log(ref);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			setModal(['商品をカートに入れました。']);
			
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};	

	const handleClick1 =  async (category1_code, category1_name) => {
		const new_array1 = [];
		const new_array2 = [];
		let flag = true;
		for(const item of category1_select){
			if(item.category1_code === category1_code){
				flag = false;
				for(const item of category2_select){
					if(item.category1_code === category1_code){
						flag = false;
						continue;
					}
					new_array2.push(item);
				}		
				continue;
			}
			new_array1.push(item);
		}
		if(flag){
			new_array1.push({category1_code : category1_code, category1_name : category1_name});
		}

		//データセット
		setCategory1Select(new_array1);
		setCategory2Select(new_array2);

		//新しい小カテゴリ
		const newCategory2 = await createCategory2({ category1_select : new_array1 });

		//データセット
		setCategory2(newCategory2);

		//データ検索
		const newData = createSearchData({ data, category1_select : new_array1, category2_select : new_array2, search});

		//データセット
		setView(newData);

		//カテゴリ検索条件の保存
		sessionStorage.setItem("category1_select", JSON.stringify(new_array1));
		sessionStorage.setItem("category2_select", JSON.stringify(new_array2));
	};
	
	const handleClick2 = (category1_code, category1_name, category2_code, category2_name) => {
		const new_array = [];
		let flag = true;
		for(const item of category2_select){
			if(item.category1_code === category1_code && item.category2_code === category2_code){
				flag = false;
				continue;
			}
			new_array.push(item);
		}
		if(flag){
			new_array.push({
				category1_code : category1_code,
				category1_name : category1_name,
				category2_code : category2_code,
				category2_name : category2_name,
			});
		}

		//データセット
		setCategory2Select(new_array);

		//データ検索
		const newData = createSearchData({ data, category1_select, category2_select : new_array, search});

		//データセット
		setView(newData);
		
		//小カテゴリ検索条件の保存
		sessionStorage.setItem("category2_select", JSON.stringify(new_array));
	};

	const handleOnChange = (event) => {

		const value = event.currentTarget.value;

		setSearch(value);

		//検索条件の保存
		sessionStorage.setItem("search", value);
	};

	const handleCreateCSV1 = () => {
	
		//対象データの抽出
		const newData = data.filter(x => selectIDs.includes(x.item_id));

		const csvData = [];
		
		//対象データの抽出
		for(const x of newData){
			const params = {
				'JAN' : x.item_id,
				'品番': x.product_no,
				'品名': x.product_name,
				'号数': x.gousu,
				'定価': x.price,
				'最低発注枚数': x.minimum_unit,
				'在庫': x.stock_flag,
			};
			csvData.push(params);
		}
		
		const dateObj      = new Date();
		const year         = dateObj.getFullYear().toString();
		const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
		const day          = ('00' + dateObj.getDate().toString()).slice(-2);
		const hour        = ('00' + dateObj.getHours().toString()).slice(-2);
		const minute      = ('00' + dateObj.getMinutes().toString()).slice(-2);
		const dateText     = String('' + year + month + day + hour + minute);

		//ファイル名
		setCsvName1('zaiko_' + dateText + '.csv');

		//CSVデータのセット
		setCSV1(csvData);
	};

	const handleShowFilter = () => {
		setShowFilter(showFilter ? false : true);
		sessionStorage.setItem("showFilter", showFilter ? 'false' : 'true');
	};
	
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/item/' + row.item_id)}>
					詳細
				</Button>
			</div>
		);
	};
	
	const formatPrice = (cell, row) => {
		return (
			<div className="text-right">
				{Number(cell ?? 0).toLocaleString()} 円
			</div>
		);
	};
	
	/*
	const formatDate = (cell, row) => {
		if(cell){
			const dateObj      = new Date(Number(cell * 1000));
			const year         = dateObj.getFullYear().toString();
			const month        = ('00' + (dateObj.getMonth()+1).toString()).slice(-2);
			const day          = ('00' + dateObj.getDate().toString()).slice(-2);
			const dateText     = (year + '年' + month + '月' + day + '日');
			return (
				<div>
					{ dateText }
				</div>
			);
		}
		else {
			return (
				<div>
					{''}
				</div>
			);
		}
	};
	*/
	
	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '詳細',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'stock_flag',
			text: '在庫',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? selectFilter({
				options : {
					'○': '○',
					'×': '×',
				}
			}) : null,
		},
		{
			dataField: 'item_id',
			text: 'JANコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'product_no',
			text: '品番',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? textFilter() : null,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const numA_product_no = parseInt(rowA?.product_no ?? 0);
				const numB_product_no = parseInt(rowB?.product_no ?? 0);
				const numA_sort = parseInt(rowA?.sort ?? 0);
				const numB_sort = parseInt(rowB?.sort ?? 0);
				if (numA_product_no < numB_product_no) {
					return order === 'asc' ? -1 : 1;
				} else if (numA_product_no > numB_product_no) {
					return order === 'asc' ? 1 : -1;
				} else {
				  // product_noが同じ場合、sortフィールドによってソート
				  if (numA_sort < numB_sort) {
						return order === 'asc' ? -1 : 1;
				  } else if (numA_sort > numB_sort) {
						return order === 'asc' ? 1 : -1;
				  } else {
						return 0;
					}
				}
			},
		},
		{
			dataField: 'sort',
			text: '並び順',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'product_name',
			text: '品名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'gousu',
			text: '号数',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? textFilter() : null,
		},
		{
			dataField: 'price',
			text: 'ﾒｰｶｰ希望本体価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
/*		{
			dataField: 'unit_price',
			text: '一般卸価格',
			sort: true,
			headerClasses: 'text-right',
			formatter: formatPrice,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: numberFilter(),
		},
*/
		{
			dataField: 'minimum_unit',
			text: '最少出荷単位',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? numberFilter() : null,
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			//filter: textFilter(),
		},
		/*
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: textFilter(),
		},
		*/
		{
			dataField: 'category2_name',
			text: '子カテゴリー名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			//filter: textFilter(),
		},
		/*
		{
			dataField: 'category2_code',
			text: '子カテゴリーコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: textFilter(),
		},
		*/
		{
			dataField: 'genre_name',
			text: 'ジャンル名',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			//filter: textFilter(),
		},
		/*
		{
			dataField: 'genre_code',
			text: 'ジャンルコード',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: textFilter(),
		},
		*/
		{
			dataField: 'catalog_no',
			text: 'カタログページ',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? numberFilter() : null,
			hidden: isMobile,
		},
		/*
		{
			dataField: 'keyword',
			text: '検索用語',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: textFilter(),
		},
		*/
		/*
		{
			dataField: 'sort',
			text: '並び順',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: numberFilter(),
		},
		*/
		{
			dataField: 'boxed_num',
			text: '内容量',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? numberFilter() : null,
			hidden: isMobile,
		},
		{
			dataField: 'url',
			text: 'URL',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? textFilter() : null,
			hidden: isMobile,
		},
		{
			dataField: 'pre_order_flag',
			text: '注残',
			sort: true,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
			filter: showFilter ? selectFilter({
				options : {
					0: '○',
					1: '×',
				}
			}) : null,
			formatter: (cell) => cell ? '×' : '○',
		},
		/*
		{
			dataField: 'date_add',
			text: '登録日時',
			sort: true,
			formatter: formatDate,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		*/
	];

	const mobileColumns = [
		{
			dataField: 'item_id',
			text: '詳細',
			sort: true,
			formatter: (cell, row) => {
				return (
					<table width="100%">
						<tbody>
						<tr>
							<td>JAN</td>
							<td>{row.item_id}</td>
						</tr>
						<tr>
							<td>在庫</td>
							<td>{row.stock_flag}</td>
						</tr>
						<tr>
							<td>品番</td>
							<td>{row.product_no}</td>
						</tr>
						<tr>
							<td>品名</td>
							<td>{row.product_name}</td>
						</tr>
						<tr>
							<td>号数</td>
							<td>{row.gousu}</td>
						</tr>
						<tr>
							<td>注残</td>
							<td>{row.pre_order_flag}</td>
						</tr>
						<tr>
							<td>価格</td>
							<td>{Number(row.price ?? 0).toLocaleString()} 円</td>
						</tr>
						<tr>
							<td colSpan="2">
								<Button variant="dark" type="button" onClick={() => handleCart(row)} disabled={lock} >
									カートに入れる
								</Button>　
								<Button variant="dark" type="button" onClick={() => history.push('/item/' + row.item_id)}>
									詳細
								</Button>
							</td>
						</tr>
						</tbody>
					</table>
				);
			},
			headerStyle:  { display: 'none' },
		},
		{
			dataField: 'product_no',
			hidden: true,
			text: '品番',
			headerStyle:  { display: 'none' },
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const numA_product_no = parseInt(rowA?.product_no ?? 0);
				const numB_product_no = parseInt(rowB?.product_no ?? 0);
				const numA_sort = parseInt(rowA?.sort ?? 0);
				const numB_sort = parseInt(rowB?.sort ?? 0);
				if (numA_product_no < numB_product_no) {
					return order === 'asc' ? -1 : 1;
				} else if (numA_product_no > numB_product_no) {
					return order === 'asc' ? 1 : -1;
				} else {
				  // product_noが同じ場合、sortフィールドによってソート
				  if (numA_sort < numB_sort) {
						return order === 'asc' ? -1 : 1;
				  } else if (numA_sort > numB_sort) {
						return order === 'asc' ? 1 : -1;
				  } else {
						return 0;
					}
				}
			},
		},
	];

	const defaultSorted = [
		{
			dataField: 'product_no',
			order: 'asc',
		}
	];

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
				
				<Modal show={modal.length ? true : false} onHide={handleClose} centered>
					<Modal.Body>
						{modal.map((msg, index) => {return (<p key={index} style={{margin: "0px"}}>{msg}</p>);})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClose}>
							商品選択を続ける
						</Button>
						<Button variant="primary" onClick={ () => { window.location.href = '/cart/'; } }>
							商品カートに移動する
						</Button>
					</Modal.Footer>
				</Modal>
				
				<Row>
					<Col sm={2}>
						<h4>商品検索</h4>
					</Col>
					<Col sm={10} style={{display : isMobile ? 'none' : 'block'}}>
						{category1.map(item =>{
							const flag = category1_select.filter(x=>x.category1_code===item.category1_code).length ? true : false;
							return(
								<Button key={item.category1_code} style={{ marginLeft: 20}} onClick={()=>handleClick1(item.category1_code, item.category1_name)} variant={flag ? 'secondary' : 'primary'}>
									{item.category1_name}
								</Button>
							);
						})}
					</Col>
				</Row>
				<Row style={{display : isMobile ? 'none' : 'block'}}>
					<Col>
						{category1_select.map(item =>{
							return(
								<Row key={item.category1_code + '2'} style={{ marginTop: 20}}>
								　{item.category1_name}：{
									category2.filter(x=>x.category1_name===item.category1_name).map(x=>{
										const flag = category2_select.filter(y=>y.category1_code===x.category1_code&&y.category2_code===x.category2_code).length ? true : false;
										return(
											<Button key={x.category1_code + x.category2_code} style={{ marginLeft: 20}} onClick={()=>handleClick2(x.category1_code, x.category1_name, x.category2_code, x.category2_name)} variant={flag ? 'secondary' : 'success'}>
											{x.category2_name}
											</Button>
										);
									})
								}
								</Row>
							);
						})}
					</Col>
				</Row>

				<Row style={{display : isMobile ? 'none' : 'block'}}>
					<Col sm={12}>
						<Form onSubmit={handleSearch} name="search">
							<Row style={{ marginTop: 20 }}>
								<Col sm={1} className="text-center">
									<Badge pill variant="secondary">
										<h6>　<font color="white">{ view.length ? view.length.toLocaleString() : '0' }</font>　</h6>
									</Badge>　
								</Col>
								<Col sm={4}>
									<Form.Group controlId="search_key">
											<Form.Control name="search_key" type="text" placeholder="Search" value={search} onChange={handleOnChange}/>
									</Form.Group>
								</Col>
								<Col sm={1}>
									<Button variant="dark" type="submit" block="true" >Search</Button>
								</Col>
								<Col sm={4}>
									<input type="checkbox" name="show_filter" value="1" checked={showFilter} onChange={handleShowFilter}/> フィルター表示
								</Col>
								<Col sm={2} className="text-right">
									<Button variant="dark" onClick={ handleReload }>
										<FaRedo />
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>

				<Row style={{display : isMobile ? 'block' : 'none'}}>
					<Col sm={12}>
						<Form onSubmit={handleSearch} name="search" className='d-flex'>
							<Form.Group controlId="search_key">
								<Form.Control name="search_key" type="text" placeholder="Search" value={search} onChange={handleOnChange}/>
							</Form.Group>
							　<Button variant="dark" type="submit" block="true" >Search</Button>
						</Form>
					</Col>
				</Row>

				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'item_id'} data={view} columns={isMobile ? mobileColumns : columns} checkbox={isMobile ? false : true} selectIDs={selectIDs} setSelectIDs={setSelectIDs} defaultSorted={defaultSorted} />
					</Col>
				</Row>
				<Form name="form" onSubmit={handleSubmit} style={{display : isMobile ? 'none' : 'block'}}>
					<Button variant="dark" type="submit" block="true" disabled={lock} >商品カートに入れる</Button>
					　
					<CSVDownload data={csv1} filename={csvName1} onClick={handleCreateCSV1}>
						在庫データCSV出力
					</CSVDownload>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
