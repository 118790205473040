import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Shipment = {};

Shipment.Get = async (shipment_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getShipment, { shipment_id }));
		const item = ref.data.getShipment;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Shipment.Scan = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanShipment));
		let items = ref.data.scanShipment.items;
		while (ref.data.scanShipment.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanShipment, { nextToken: ref.data.scanShipment.nextToken }));
			items = items.concat(ref.data.scanShipment.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Shipment.Query = async (order_id) =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.queryShipment, { order_id } ));
		let items = ref.data.queryShipment.items;
		while (ref.data.queryShipment.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.queryShipment, { order_id, nextToken: ref.data.queryItemProduct.nextToken }));
			items = items.concat(ref.data.queryShipment.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Shipment.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putShipment, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Shipment.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateShipment, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Shipment.Delete = async (shipment_id) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteShipment, { shipment_id }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Shipment.Cancel = async (shipment_id) =>  {
	try{
		await API.graphql(graphqlOperation(GraphQL.updateShipmentCancel, { shipment_id }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

export default Shipment;


