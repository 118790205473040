import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	//Form,
	Button,
	//Badge,
} from 'react-bootstrap';
//import { FaRedo } from 'react-icons/fa';
import Table from '../../Objects/Table';
import { useHistory, withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { textFilter } from 'react-bootstrap-table2-filter';


const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [reload, setReload] = useState(0);
	//const [data, setData] = useState([]);
	const [view, setView] = useState([]);
	const [alert, setAlert] = useState([]);

	useEffect(() => {
	
		const getData = async () => {
			
			try{
				//件数の取得
				const ref = await API.Category1.Scan();
				
				//データセット
				//setData(ref.items);
				setView(ref.items);
			}
			catch(err){
				console.log(err);
			}

			//表示開始
			setLoad(false);
			
		};
		
		getData();
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[reload]);
	
	/*
	const handleReload = () => {
		setReload(reload+1);
	};
	
	const handleSearch = (event) => {
		
		//Submitをキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formオブジェクトの確認
		const formObj = event.currentTarget;
		
		//Formから直接取得する更新項目
		const search_key           = formObj.search_key.value;
		
		//検索文字オブジェクト
		const matchString = new RegExp(search_key);
		
		//検索
		const redData = data.filter(item => Object.keys(item).map(key => (typeof item[key] === 'string' ? (item[key].match(matchString) !== null ? true : false) : false ) ).filter(x => x === true).length !== 0);
		
		setView(redData);

	};
	*/
		
	const handleClose = () => {
		setAlert([]);
		if(alert[0] ==='削除しました。'){
			window.location.href = '/admin/item/';
		}
		else{
			setReload(reload+1);
		}
	};
	
	const formatButton = (cell, row) => {
		return (
			<div className="text-center">
				<Button variant="dark" type="button" onClick={() => history.push('/category/' + row.category1_code)}>
					検索
				</Button>
			</div>
		);
	};
		
	const columns = [
		{
			dataField: 'dummy',
			isDummyField: true,
			text: '検索',
			sort: false,
			formatter: formatButton,
			headerStyle:  { whiteSpace : 'nowrap' },
			style:  { whiteSpace : 'nowrap' },
		},
		{
			dataField: 'category1_code',
			text: '親カテゴリーコード',
			sort: true,
			filter: textFilter(),
		},
		{
			dataField: 'category1_name',
			text: '親カテゴリー名',
			sort: true,
			filter: textFilter(),
		},
	];
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClose} />
								
				<Row>
					<Col sm={12}>
						<h4>カテゴリ検索</h4>
					</Col>
				</Row>
				<Row style={{ marginTop: 20 }} >
					<Col sm={12}>
						<Table keyField={'category1_code'} data={view} columns={columns} checkbox={false} />
					</Col>
				</Row>
			</Container>
		);
	}
};

export default withRouter(Default);
