//パスワードの再申請の確認コードを発行する

import { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Modal,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
//import { AuditLog } from '../../Objects/AuditLog';
import { ModalAlert } from '../../Objects/Modal';

const Default = (props) => {

	const history = useHistory();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);
	const [email, setEmail] = useState(false);
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);

		//更新処理
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//Formから値取得
			const email = formObj.email.value
			
			//記録
			setEmail(email);
			
			//パスワード再設定
			await Auth.forgotPassword(email);
			
			//成功
			setSuccess(true);
		}
		catch(err) {
			setAlert([err.message]);
		}
		
		//ロック解除
		setLock(false);
		
	};
	
	const handleClick = async (event) => {
		window.location.href='/forgot-password-verify' + (email ? '/' + email : '') + '/';
	};

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item href="/">ログイン</Breadcrumb.Item>
				<Breadcrumb.Item active>パスワード再登録（確認コードの発行）</Breadcrumb.Item>
			</Breadcrumb>
			<Container style={{ marginTop: 200, marginBottom: 200 }}>
				<ModalAlert data={alert} />

				<Modal show={success} onHide={handleClick}>
					<Modal.Body>
						パスワード再登録の申請を行いました。<br/>
						次のページでメールアドレスで受け取った確認コードと新しいパスワードを入力してください。<br/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={handleClick}>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
				
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								<big>パスワード再登録（確認コードの発行）</big><br/>
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<p className="text-center">
								パスワードを再登録するための確認コードをお送りします。メールアドレスを入力してください。
							</p>
						</Col>
					</Row>
					<Row>
						<Col sm={{ span: 4, offset: 4 }}>
							<Form.Group controlId="email">
							        <Form.Control name="email" type="email" placeholder="メールアドレス" required disabled={lock} />
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="dark" type="submit" block="true" disabled={lock} >確認コードの発行</Button>
						        <p style={{ marginTop: 10 }}>確認コードを発行済みの方は<a href="/forgot-password-verify/">こちら</a>で確認コードを使用してパスワードを変更することができます。</p>
						</Col>
					</Row>
					<Row style={{ marginTop: 50 }}>
						<Col sm={{ span: 4, offset: 4 }}>
						        <Button variant="secondary" type="button" block="true" onClick={ ()=>{ history.goBack(); } } disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		</div>
	);
}

export default withRouter(Default);
