import { useState, useEffect  } from 'react';
import API from '../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Image,
	Button,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';
import { Scanner } from '../../Objects/Scanner';

const Default = () => {

	const [load, setLoad] = useState(true);
	const [alert, setAlert] = useState([]);
	const [itemId, setItemId] = useState(null);
	const [scanning, setScanning] = useState(true); // スキャン状態を管理
	const [scannedItem, setScannedItem] = useState({});

	useEffect(() => {
		
		//表示開始
		setLoad(false);

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	useEffect(() => {
		
		const createScanData = async () => {

			if(itemId === null){
				return;
			}

			const refItem = await API.Item.Get(itemId);
			const item = refItem?.item ?? null;

			if(!item || Object.keys(item).length === 0){
				return;
			}

			if(scanning === false){
				return;
			}
	
			setScanning(false);

			setScannedItem ({
				item_id : itemId,
				product_no : item.product_no,
				product_name : item.product_name,
				gousu : item.gousu,
				price : Number(item?.price ?? 0),
				unit_price : Number(item?.unit_price ?? 0),
				piece : Number(item?.minimum_unit ?? 1),
				minimum_unit : item.minimum_unit,
				category1_name : item.category1_name,
				category1_code : item.category1_code,
				category2_name : item.category2_name,
				category2_code : item.category2_code,
				genre_name : item.genre_name,
				genre_code : item.genre_code,
				catalog_no : item.catalog_no,
				keyword : item.keyword,
				sort : item.sort,
				boxed_num : item.boxed_num,
				url : item.url,
				pre_order_flag : item.pre_order_flag,
				pre_order_request : item?.pre_order_flag ? 1 : 0,
				stock_flag : item.stock_flag,
				message : '',
			});

		};

		createScanData();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[itemId]);

	const handleAddCart = async (data) => {

		//カートに追加
		try {

			if(Object.keys(scannedItem).length === 0){
				throw new Error('商品が見つかりませんでした。');
			}

			//削除
			const ref = await API.Cart.Add(scannedItem);

			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//初期化
			setItemId(null);
			setScannedItem({});
			setAlert(['商品をカートに入れました。']);

		}
		catch(err){
			setAlert([err.message]);
		}

	};

	const handleDetected = (item_id) => {
		setItemId(item_id);
	};

	const handleClear = () => {
		setItemId(null);
		setScannedItem({});
		setAlert([]);
		setTimeout(() => {
			setScanning(true);
		}, 2000); // 2秒の遅延
	};

	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 20, marginBottom: 200 }} fluid>
				<ModalAlert data={alert} onClick={handleClear} />
				<Modal show={Object.keys(scannedItem).length} onHide={handleClear}>
					<Modal.Header closeButton>
						<Modal.Title>カートに入れますか？</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>JANコード：{scannedItem.item_id}</p>
						<p>品番：{scannedItem.product_no}</p>
						<p>品名：{scannedItem.product_name}</p>
						<p>号数：{scannedItem.gousu}</p>
						<p>定価：{Number(scannedItem?.price ?? 0).toLocaleString()}円</p>
						<p>最低発注枚数：{Number(scannedItem.minimum_unit ?? 0).toLocaleString()}個</p>
						<p>在庫管理：{scannedItem.stock_flag}</p>
						<Button variant="primary" onClick={handleAddCart}>カートに入れる</Button>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClear}>
							閉じる
						</Button>
					</Modal.Footer>
				</Modal>

				<Row>
					<Col sm={12}>
						<h4>バーコード発注</h4>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Button variant="primary" onClick={ () => { window.location.href = '/cart/'; } }>
									商品カートに移動する
						</Button>
					</Col>
				</Row>

				<Row style={{ marginTop: 20}}>
					<Col sm={12}>
						{scanning ?
							<section className="section-wrapper">
								<div className="section-title">
									<h1 className="section-title-text">
										<Scanner onDetected={handleDetected} />
									</h1>
								</div>
							</section>
							: '起動中...'
						}
					</Col>
				</Row>

			</Container>
		);
	}
};

export default withRouter(Default);
