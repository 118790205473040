import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const Category1 = {};

Category1.Get = async (category1_code) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getCategory1, { category1_code: category1_code }));
		const item = ref.data.getCategory1;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

Category1.Scan = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanCategory1));
		let items = ref.data.scanCategory1.items;
		while (ref.data.scanCategory1.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanCategory1, { nextToken: ref.data.scanCategory1.nextToken }));
			items = items.concat(ref.data.scanCategory1.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

Category1.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putCategory1, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Category1.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateCategory1, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

Category1.Delete = async (category1_code) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.deleteCategory1, { category1_code: category1_code }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default Category1;


