import { useState, useEffect  } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Breadcrumb,
	Image,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';

const Default = () => {

	const history = useHistory();
	const [load, setLoad] = useState(true);
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [user_data, setUserData] = useState([]);

	useEffect(() => {
	
		const getData = async () => {

			//ユーザ情報の取得
			const refUser = await API.User.Scan();
			const itemsUser = refUser.items;

			setUserData(itemsUser);

			setLoad(false);

		};
			
		getData();

		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const handleSubmit = async (event) => {
	
		// イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		// フォームをロック
		setLock(true);
		
		try {
			
			// フォームオブジェクトの取得
			const formObj = event.currentTarget;
			
			// フォームオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			// フォームのバリデーションチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}

			if(!formObj.user_no.value){
				throw new Error('Please enter the user code');
			}
			if(!formObj.order_no.value){
				throw new Error('Please enter the order number');
			}
			if(!formObj.item_id.value){
				throw new Error('Please enter the JAN code');
			}
			if(!formObj.backorder_num.value){
				throw new Error('Please enter the backorder_num');
			}

			//JANコードの指定がある場合、既存データを取得
			const refItem = await API.Item.Get(formObj.item_id.value);
			const itemItem = refItem.item;
			const product_no = itemItem?.product_no ?? null;
			const product_name = itemItem?.product_name ?? null;
			const gousu = itemItem?.gousu ?? null;

			//ユーザIDの指定がある場合、既存データを取得
			const user_id = user_data.filter(user => user.user_no === formObj.user_no.value)?.[0]?.user_id ?? null;

			const params = {
				shipment_id: formObj.order_no.value + '-' + formObj.item_id.value,
				user_no: formObj.user_no.value,
				user_id: user_id,
				order_date: formObj.order_date.value,
				delivery_kbn: formObj.delivery_kbn.value,
				item_id: formObj.item_id.value,
				product_no: product_no,
				product_name: product_name,
				gousu: gousu,
				order_num: Number(formObj?.order_num?.value ?? 0),
				user_order_no: formObj.user_order_no.value,
				biko: formObj.biko.value,
				order_id: formObj.order_id.value,
				pre_order_flag: formObj?.pre_order_flag?.value ? 1 : 0,
				pre_order_request: formObj?.pre_order_request?.value ? 1: 0,
				shipping: Number(formObj?.shipping?.value ?? 0),
				order_no: formObj.order_no.value,
				tracking_no: formObj.tracking_no.value,
				shipment_num: Number(formObj?.shipment_num?.value ?? 0),
				backorder_num: Number(formObj?.backorder_num?.value ?? 0),
				processing_date: formObj.processing_date.value,
				due_date: formObj.due_date.value,
				backorder_days: Number(formObj?.backorder_days?.value ?? 0),
				shipping_date: formObj.shipping_date.value,
			};
			console.log(params);
			
			// 更新
			const ref = await API.Shipment.Put(params);
			
			// エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			// フォームリセット
			formObj.reset();
			
			throw new Error('出荷情報を追加しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		// ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		//window.location.reload();
	};
	
	if(load){
		return (
			<Row style={{ marginTop: 200, marginBottom: 200 }}>
				<Col sm={12} className="text-center">
					<Image src="/img/loading.gif" />
				</Col>
			</Row>
		);
	}
	else{
		return (
			<Container style={{ marginTop: 50, marginBottom: 200 }}>
				<ModalAlert data={alert} onClick={handleClose} />

				<Breadcrumb>
					<Breadcrumb.Item href="/admin/shipment/">出荷情報</Breadcrumb.Item>
					<Breadcrumb.Item active>新規登録</Breadcrumb.Item>
				</Breadcrumb>

				<Form name="form" onSubmit={handleSubmit}>

					<Row>
						<Col sm={6}>
							<p><big>新規登録</big></p>
						</Col>
					</Row>
					<hr/>
					<hr/>
					<FormText label={'得意先コード'} name={'user_no'} required={true} />
					<FormText label={'受注日'} name={'order_date'} />
					<FormText label={'直送区分'} name={'delivery_kbn'} />
					<FormText label={'JANコード'} name={'item_id'} required={true} />
					<FormText label={'発注数'} name={'order_num'} type={'number'} />
					<FormText label={'客注番号'} name={'user_order_no'} />
					<FormText label={'備考'} name={'biko'} />
					<FormText label={'web受注番号'} name={'order_id'} />
					<FormText label={'注残'} name={'pre_order_flag'} type={'number'} />
					<FormText label={'注残管理'} name={'pre_order_request'} type={'number'} />
					<FormText label={'送料'} name={'shipping'} type={'number'} />
					<FormText label={'受注No.'} name={'order_no'} required={true} />
					<FormText label={'問い合せNo.'} name={'tracking_no'} />
					<FormText label={'出荷数'} name={'shipment_num'} type={'number'} />
					<FormText label={'注残数'} name={'backorder_num'} type={'number'} />
					<FormText label={'処理日'} name={'processing_date'} />
					<FormText label={'納期'} name={'due_date'} />
					<FormText label={'注残日数'} name={'backorder_days'} type={'number'} />
					<FormText label={'出荷日'} name={'shipping_date'} />
					<hr/>
					
					<Row style={{ marginTop: 10 }}>
						<Col sm={{ span: 6, offset: 3 }}>
								<Button variant="dark" type="submit" block="true" disabled={lock} >登録</Button>
								　
								<Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
						</Col>
					</Row>
				</Form>
			</Container>
		);
	}
};

export default withRouter(Default);
