import { useState } from 'react';
import API from '../../../@onocomm-system';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Breadcrumb,
} from 'react-bootstrap';
import { useHistory, withRouter } from 'react-router-dom';
import { FormText } from '../../../Objects/Form';
import { ModalAlert } from '../../../Objects/Modal';


const Default = () => {

	const history = useHistory();
	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	
	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);
		
		try {
			
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			const params ={
				category1_code : formObj.category1_code.value,
				category1_name : formObj.category1_name.value,
			};
			console.log(params);
			
			//更新
			const ref = await API.Category1.Put(params);
			
			//エラー確認
			if(ref.error){
				throw new Error('入力エラーがありました。(' + ref.error + ')');
			}
			
			//フォームリセット
			formObj.reset();
			
			throw new Error('追加しました。');
		}
		catch(err){
			setAlert([err.message]);
		}
		//ロック解除
		setLock(false);
	};
	
	const handleClose = () => {
		setAlert([]);
		//window.location.reload();
	};
	
	return (
		<Container style={{ marginTop: 50, marginBottom: 200 }}>
			<ModalAlert data={alert} onClick={handleClose} />

			<Breadcrumb>
				<Breadcrumb.Item href="/admin/category1/">親カテゴリー情報</Breadcrumb.Item>
				<Breadcrumb.Item active>新規登録</Breadcrumb.Item>
			</Breadcrumb>

			<Form name="form" onSubmit={handleSubmit}>

				<Row>
					<Col sm={6}>
						<p><big>新規登録</big></p>
					</Col>
				</Row>
				<hr/>
				<FormText label={'親カテゴリーコード'} name={'category1_code'} required={true} />
				<FormText label={'親カテゴリー名'} name={'category1_name'} required={true} />
				<hr/>
				
				<Row style={{ marginTop: 10 }}>
					<Col sm={{ span: 6, offset: 3 }}>
					        <Button variant="dark" type="submit" block="true" disabled={lock} >登録</Button>
							　
					        <Button variant="secondary" type="button" block="true" onClick={()=>{ history.goBack(); }} disabled={lock} >もどる</Button>
					</Col>
				</Row>
			</Form>
		</Container>
	);
};

export default withRouter(Default);
