import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from '../include/GraphQL.js';

const User = {};

User.Current = async () =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getUserCurrent));
		const item = ref.data.getUserCurrent;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

User.Get = async (user_id) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.getUser, { user_id: user_id }));
		const item = ref.data.getUser;
		return ({
			item: item ? item : {},
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			item: {},
			error: JSON.stringify(err),
		});
	}
}

User.Scan = async () =>  {
	try{
		let ref = await API.graphql(graphqlOperation(GraphQL.scanUser));
		let items = ref.data.scanUser.items;
		while (ref.data.scanUser.nextToken) {
			ref  = await API.graphql(graphqlOperation(GraphQL.scanUser, { nextToken: ref.data.scanUser.nextToken }));
			items = items.concat(ref.data.scanUser.items);
		}
		
		return ({
			items: items,
			error: null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			items: [],
			error: JSON.stringify(err),
		});
	}
}

User.Put = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.putUser, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

User.Update = async (params) =>  {
		
	try{
		await API.graphql(graphqlOperation(GraphQL.updateUser, { data: params }));
		return ({
			result: true,
			error: null,
		});
	}
	catch(err) {
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}

User.Operation = async (flag, params) =>  {
		
	try{
		const ref = await API.graphql(graphqlOperation(GraphQL.executeUserOperation, { flag: flag, data: params }));
		const result = ref.data.executeUserOperation.result;
		const error = ref.data.executeUserOperation.error;
		return({
			result: result ? true : false,
			error: error ? error : null,
		});
	}
	catch(err) {
		console.log(err);
		return({
			result: false,
			error: JSON.stringify(err),
		});
	}
}


export default User;


