import { useEffect  } from 'react';
import Quagga from "quagga";

const config = {
	"inputStream": {
		"type": "LiveStream",
		"constraints": {
			"width": {
				"min": 450
			},
			"height": {
				"min": 300
			},
			"facingMode": "environment",
			"aspectRatio": {
				"min": 1,
				"max": 2,
			},
		},
	},
	"locator": {
		"patchSize": "medium",
		"halfSample": true
	},
	"numOfWorkers": 2,
	"frequency": 10,
	"decoder": {
		"readers": ["ean_reader"]
	},
	"locate": true
};

const Scanner = (props) => {

	const { onDetected } = props;

	useEffect(() => {

		Quagga.init(config, err => {
			if (err) {
				console.log(err, "error msg");
			}
			Quagga.start();
			return () => {
				Quagga.stop()
			}
		});

		Quagga.onProcessed(
			result => {
				var drawingCtx = Quagga.canvas.ctx.overlay,
					drawingCanvas = Quagga.canvas.dom.overlay;

				if (result) {
					if (result.boxes) {
					drawingCtx.clearRect(
						0,
						0,
						Number(drawingCanvas.getAttribute("width")),
						Number(drawingCanvas.getAttribute("height"))
					);
					result.boxes
						.filter(function(box) {
						return box !== result.box;
						})
						.forEach(function(box) {
						Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
							color: "green",
							lineWidth: 2
						});
						});
					}

					if (result.box) {
					Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
						color: "#00F",
						lineWidth: 2
					});
					}

					if (result.codeResult && result.codeResult.code) {
						Quagga.ImageDebug.drawPath(
						result.line,
						{ x: "x", y: "y" },
						drawingCtx,
						{ color: "red", lineWidth: 3 }
						);
					}
				}
			}
		);

	    Quagga.onDetected(detected);
		
		//eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const detected = (result) => {
		onDetected(result.codeResult.code);
	};

	return (
		<div id="interactive" className="viewport" />
	);
};

export { Scanner };
