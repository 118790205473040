import { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
	Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ModalAlert } from '../../Objects/Modal';


const Default = (props) => {

	const [lock, setLock] = useState(false);
	const [alert, setAlert] = useState([]);
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (event) => {
	
		//イベントキャンセル
		event.preventDefault();
		event.stopPropagation();

		//Formをロック
		setLock(true);

		//更新処理
		try{
			//Formオブジェクトの取得
			const formObj     = event.currentTarget;
			
			//Formオブジェクトの確認
			if(!formObj){
				throw new Error('An invalid error has occurred');
			}
			
			//Formのvalidationチェック
			if(formObj.checkValidity() === false){
				throw new Error('There was an error in the input item');
			}
			
			//Formから値取得
			const code = formObj.code.value
			
			//コード登録
			const result = await Auth.verifyUserAttributeSubmit((await Auth.currentAuthenticatedUser()), 'email', code);
			
			//debug
			console.log(result);
			
			//成功
			setSuccess(true);
		}
		catch(err) {
			setAlert([err.message]);
		}
		
		//ロック解除
		setLock(false);
		
	}

	const handleClick = async (event) => {
		window.location.href='/account';
	};

	return (
		<Container style={{ marginTop: 200, marginBottom: 200 }}>
			<ModalAlert data={alert} />
			
			<Modal show={success} onHide={handleClick}>
				<Modal.Body>
					メールアドレスの変更が完了しました。<br/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClick}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>

			<Row>
			<Col sm={{ span: 8, offset: 2 }}>
				<Alert variant="info">
					<p>お客様の新しいメールアドレス宛にメールアドレスを確認する検証コードを送信しています。</p>
					<p>届いたメールの内容に従って、検証コードを入力してください。</p>
					<p>メールが届かない場合は、ご入力を頂いたメールアドレスに間違いがないかご確認の上、再度登録ください。</p>
				</Alert>
			</Col>
			</Row>

			<Form onSubmit={handleSubmit}>
			<Row>
				<Col sm={{ span: 4, offset: 4 }}>
					<p className="text-center"><big>メールアドレスの検証</big></p>
				</Col>
			</Row>
			<Row>
				<Col sm={{ span: 4, offset: 4 }}>
					<Form.Group controlId="formCode">
					        <Form.Control name="code" type="text" placeholder="検証コード" required disabled={lock} />
					</Form.Group>
				</Col>
			</Row>
			<Row style={{ marginTop: 10 }}>
				<Col sm={{ span: 4, offset: 4 }}>
				        <Button variant="dark" type="submit" block="true" disabled={lock}>入力</Button>
				</Col>
			</Row>
			</Form>
		</Container>
	);
}

export default withRouter(Default);

